<template>
  <table-container :list="list" height="750">
    <el-table-column type="index" label="序号" width="80"> </el-table-column>
    <el-table-column prop="name" label="类型"> </el-table-column>
    <el-table-column prop="sort" label="排序"> </el-table-column>
    <el-table-column prop="updated_at" label="时间"> </el-table-column>
    <el-table-column prop="supplier_23" label="操作">
      <template slot-scope="scope">
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-edit"
          @click="handleEdit(scope.row)"
          >编辑</el-button
        >
        <el-button
          type="danger"
          size="mini"
          icon="el-icon-delete"
          @click="handleDelete(scope.row)"
          >删除</el-button
        >
      </template>
    </el-table-column>
  </table-container>
</template>
<script>
export default {
  name: "List",
  props: {
    list: Array,
  },
  methods: {
    handleEdit(e) {
      this.$emit("edit", e);
    },
    handleDelete(item) {
      this.$confirm(
        `是否确定删除类型:<strong style="color:red">${item.name}</strong>?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          return this.$store.dispatch("deleteLossType", { id: item.id });
        })
        .then((res) => {
          this.$tool.comfirmToast(res);
          this.$emit("succ");
        });
    },
  },
};
</script>
<style lang="less" scoped>
</style>