<template>
  <div class="lossType-container">
    <loss-nav @add="handleAddClick"></loss-nav>
    <list :list="typeList" @edit="handleEditClick" @succ="handleSucc"></list>
    <add-type
      :show="visible"
      :type="type"
      :item="item"
      :lossName="item.name"
      :lossSort="item.sort"
      :lossId="item.id"
      @visible="handleChangeShow"
      @succ="handleSucc"
    ></add-type>
  </div>
</template>
<script>
import AddType from "./components/AddType";
import LossNav from "./components/Nav";
import List from "./components/List";
export default {
  name: "LossType",
  components: {
    LossNav,
    List,
    AddType,
  },
  data() {
    return {
      typeList: [],
      visible: false,
      type: "add",
      item: {},
    };
  },
  created() {
    this.handleInit();
  },
  methods: {
    //初始化
    async handleInit() {
      this.getAllLossType();
    },
    //获取类型列表
    async getAllLossType() {
      const res = await this.$store.dispatch("getAllLossType");
      this.typeList = res.types;
    },
    //是否展示弹窗
    handleChangeShow(visible = true) {
      this.visible = visible;
    },
    //新增提交
    handleAddComfirm(data) {
      this.$store.dispatch("addLossType");
    },
    //提交成功
    handleSucc() {
      this.visible = false;
      this.getAllLossType();
    },
    //新增
    handleAddClick() {
      this.visible = true;
      this.item = {};
      this.type = "add";
    },
    //编辑
    handleEditClick(e) {
      this.item = e;
      this.type = "edit";
      this.visible = true;
    },
  },
};
</script>
<style lang="less" scoped>
.lossType-container {
  width: 100%;
}
</style>