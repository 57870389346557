<template>
  <el-dialog
    title="新增类型"
    :visible.sync="visible"
    append-to-body
    width="30%"
  >
    <el-form label-width="50px">
      <el-form-item label="类型:">
        <el-input v-model="name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="排序:">
        <el-input v-model="sort" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="error" @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleComfirm">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "AddType",
  props: {
    show: Boolean,
    type: {
      type: String,
      default: "add",
    },
    lossId: [String, Number],
    lossName: String,
    lossSort: [String, Number],
  },
  data() {
    return {
      visible: false,
      name: "",
      sort: 1,
      id: "",
    };
  },
  watch: {
    show(e) {
      this.visible = e;
    },
    visible(e) {
      this.$emit("visible", e);
    },
    lossId() {
      this.name = this.lossName;
      this.id = this.lossId;
      this.sort = this.lossSort;
    },
    type(e) {
      if (e === "edit") {
        this.name = this.lossName;
        this.id = this.lossId;
        this.sort = this.lossSort;
      }
    },
  },
  methods: {
    //取消
    handleCancel() {
      this.visible = false;
    },
    //提交
    handleComfirm() {
      if (this.type === "add") {
        this.handleAdd();
      } else {
        this.handleEdit();
      }
    },
    //新增
    async handleAdd() {
      const res = await this.$store.dispatch("addLossType", {
        name: this.name,
        sort: this.sort,
      });
      if (res.code === 0) {
        this.$message({
          message: "新增成功",
          type: "success",
        });
        this.$emit("succ");
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    //编辑
    async handleEdit() {
      const res = await this.$store.dispatch("editLossType", {
        name: this.name,
        sort: this.sort,
        id: this.id,
      });
      if (res.code === 0) {
        this.$message({
          message: "修改成功",
          type: "success",
        });
        this.$emit("succ");
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
</style>